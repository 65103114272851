import KEY from '../../../../const/storeKey'
const { ACTIONS, _NEW } = KEY
const ACTION = ACTIONS._NEW

export default {
    KEY_NAME: _NEW,
    ACTION,
    namespaced: true,
    state: {
        posts: []
    },
    getters: {
        posts: state => state.posts,
    },
    mutations: {
        CHANGE_POSTS(state, posts) {
            if (!posts.length) return state.posts = [];
            state.posts = posts.map(i => ({
                _id: i._id,
                categoryId: i.categoryId,
                title: i.title,
                image: i.image,
                tags: Array.isArray(i.tags) ? i.tags : i.tags.trim().split(","),
                createTime: i.createTime
            })
            )
        }
    },
    actions: {
        CHANGE_POSTS({ commit }, posts) {
            commit(ACTION.CHANGE_POSTS, posts);
        }
    }
}
